import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

function ShowcaseCard({ id, title, shortDescription, thumbnail }) {
  const image = getImage(thumbnail);
  return (
    <div className="flex flex-col text-white">
      <Link key={id} to={`/showcase/${id}`}>
        <div>
          <GatsbyImage
            className="h-[373px] md:h-[278px] rounded-lg border border-blue-100 mobile-showcase-image"
            image={image}
            alt={title}
          />
        </div>
        <h3 className="text-2xl text-blue-100 mt-4 min-h-0 md:min-h-[2.8em]">
          {title}
        </h3>
      </Link>
      <p className="text-md text-white mt-3">{shortDescription}</p>
    </div>
  );
}

export default ShowcaseCard;
