import React from "react";
import { graphql, Link } from "gatsby";

import Page from "./../components/Page";
import SEO from "./../components/SEO";
import ShowcaseCard from "./../components/ShowcaseCard";
import Section from "../components/Section";

function Showcases({ data }) {
  const showcases = data.allShowcasesJson.nodes;
  return (
    <Page>
      <SEO title="Product showcase | OHIF" />
      <Section className="my-16 bg-blue-800">
        <div className="flex justify-between items-baseline mobile-showcase">
          <h1 className="text-5xl text-blue-100 mb-5">Showcase</h1>
          <span>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSf-03j6fXENg-tyadPHOM7lfvpWRjoDecyAoOLSBXMk3PlVOQ/viewform?usp=sf_link"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-100 font-bold hover:opacity-75"
            >
              + Submit Your Project
            </a>
          </span>
        </div>
        <div className="lg:grid lg:grid-cols-12">
          <div className="xl:col-span-12 lg:col-span-12">
            <div className="grid mt-8 md:grid-cols-2 lg:grid-cols-3 text-white gap-y-10 md:gap-y-16 gap-x-10 md:gap-x-16 mobile-showcase">
              {showcases.map((showcase) => {
                const { id } = showcase;
                return <ShowcaseCard {...showcase} id={id} />;
              })}
            </div>
          </div>
        </div>
      </Section>
    </Page>
  );
}

export const query = graphql`
  {
    allShowcasesJson(sort: { fields: sortIndex }) {
      nodes {
        id
        title
        shortDescription
        thumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default Showcases;
